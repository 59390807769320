@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@600&display=swap');/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap'); */

.App {
  text-align: center;
}


.css-14sza3e-MuiStepLabel-root { 
  text-align: right !important;
}

.marginRight-xs {
  margin-right: 1% !important;
}
.white {
  color: white !important;
}

.blue {
  color: #0099ff !important;
}


.margin-xs {
  margin: 2% !important;
}

html {
    min-height: calc(100% + env(safe-area-inset-top));
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
    overflow-y: auto;
    font-family: 'Rubik';
}

body, #root, #app, #app>div {
  height: 100% !important;
  overflow-y: auto;
  font-family: 'Rubik';
}

.safe-area {
    padding: env(safe-area-inset-top) 0 0 env(safe-area-inset-left);
}

.wave-as-background {
    /* background-image: url("./images/wave.svg"); */
    background-position: top;
    background-repeat: no-repeat;
    background-size: contain;
    background-attachment: fixed;
}

.green {
  color: #348F50 !important;
}

.purple {
  color : #7474BF !important;
}

.bg-white {
  background-color: white !important;
}

.border-r40 {
  border-radius: 40px !important;
}

.purple-background {
    background-image: linear-gradient(to right top, #510085, #642193, #7738a2, #8a4eb0, #9c64bf) !important;
}

.yellow-background {
    background-image: linear-gradient(to right top, #ff7e00, #ff9100, #ffa400, #ffb700, #ffc900) !important;
}

.color-liner-bg {
    background: linear-gradient(to top, #000428, #004e92); 
    /* background: linear-gradient(to top, var(--mui-palette-primary-dark), var(--mui-palette-primary-light)) !important; */
}
.color-liner {
  /* background: #56CCF2;  /* fallback for old browsers */
  /* background: linear-gradient(to top, var(--mui-palette-primary-main), var(--mui-palette-custom-main)) !important; */
    background: linear-gradient(to top, var(--mui-palette-primary-dark), var(--mui-palette-primary-light)) !important;  /*W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+*/
}

  .color-liner-lightblue {
    background: linear-gradient(to top right, #667db6, var(--mui-palette-primary-main), var(--mui-palette-primary-main), #667db6);
  }

.justify {
  justify-content: center !important;
  justify-items: center !important;
}
.div-scroll-mobile {
  overflow: overlay !important;
  max-height: 130px !important;
}
.div-scroll-desktop {
  overflow: overlay !important;
  max-height: 160px !important;
}
.MuiSpeedDialAction-staticTooltipLabel {
  width: max-content !important;
}
.space-around {
  justify-content: space-around !important;
}
.overflow-hidden {
  overflow: hidden !important;
}

.width80{
  width: 80% !important;
}

.width60{
  width: 60% !important;
}

.width50{
  width: 50% !important;
}
.instagram-gradient {
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%) !important;
}
.whatsapp-gradient {
  background: linear-gradient(#25d366,#25d366) 14% 84%/16% 16% no-repeat, radial-gradient(#25d366 100%,transparent 0) !important;
}
.google-gradinet {
  background: linear-gradient(289deg, #4285f4, #34a853, #fbbc05, #ea4335) !important;
}

.HomeSvg {
  width: 80% !important;
  margin: 12% !important;
}

.max-height-width {
  width: 100%;
  height: 100%;
}
.lightgray-bg {
  background-color: #fafafafa  !important;
}
.max-height-width-xl {
  width: 100%;
  height: 105%;
}
.back {
  position: absolute;
  z-index: -1;
}


.firebaseui-idp-button {
    border-radius: 80px !important;
}


.mobileHomePapper {
    margin-top: 8px;
    border-top-right-radius: 4%;
    border-top-left-radius: 4%;
    height: 100%;
}


.black {
  color: black !important;
}

.ltr {
  direction: ltr !important;
}

.transparent {
  background-color: transparent !important;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.rtl {
  direction: rtl !important;
}

.marginRight-md {
  margin-right: 5% !important;
}
.box-none {
  box-shadow: none !important;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}

.marginLeft6px {
  margin-left: 6px !important;
}
.App-link {
  color: #61dafb;
}

.bold {
  font-weight: 900 !important;
}

.signbutton {
  background-color: #348F50 !important;
  color: white !important;
}

.width100{
  width: 100% !important;
}
.width50 {
  width: 50% !important;
}
.paddingLeft0{
  padding-left: 0% !important;
}

.cactus-contain {
  position: fixed !important;
  bottom: 0 !important ;
  z-index: 1 !important;
}

.cactus {
  position: sticky !important;
  max-height: 13em !important;
}

.flowImage {
    height: 200px;
    width: 200px;
    position: relative;
}

.__react_modal_image__icon_menu {
    float: none !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
